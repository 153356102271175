import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../../subcomponents/Config";
import { useCookies } from "react-cookie";
import {
	Card,
	CardContent,
	Typography,
	Box,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Chip,
	CircularProgress,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import Pagination from "../adminComponents/Pagination/Pagination";

export default function DownloadedLog() {
	const [downloadLogs, setDownloadLogs] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 10;
	const [totalPages, setTotalPages] = useState(0);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const nav = useNavigate();
	const [cookies] = useCookies(["adminToken"]);
	const [adminToken, setAdminToken] = useState(cookies.adminToken || "");
	useEffect(() => {
		setAdminToken(cookies.adminToken || "");
	}, [cookies]);

	useEffect(() => {
		getData();
	}, [currentPage]);

	function handleDetails(userId) {
		nav(`/admin/SUPER_ADMIN/download/details/${userId}`);
	}

	const getData = async () => {
		setLoading(true);
		try {
			const response = await fetch(
				`${BASE_URL}/api/v1/videoLog/list?page=${currentPage}&size=${itemsPerPage}`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${adminToken}`,
					},
				}
			);
			if (response.ok) {
				const apiData = await response.json();
				setDownloadLogs(apiData.downloadLogs);
				setTotalPages(apiData.totalPages);
				setLoading(false);
			} else {
				const error = await response.json();
				setError(error.message);
				setLoading(false);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	const pageNumbers = [];
	for (let i = 1; i <= totalPages; i++) {
		pageNumbers.push(i);
	}

	return (
		<Box>
			<ToastContainer position="top-center" autoClose="2000" />
			<Card variant="outlined">
				<CardContent>
					<Box sx={{ display: "flex", justifyContent: "space-between" }}>
						<Typography variant="h3">Downloaded Videos Log</Typography>
					</Box>

					<Box
						sx={{
							overflow: {
								xs: "auto",
								sm: "unset",
							},
						}}
					>
						<Table
							aria-label="simple table"
							sx={{
								mt: 3,
							}}
						>
							<TableHead>
								<TableRow>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											User Name
										</Typography>
									</TableCell>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Course Name
										</Typography>
									</TableCell>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Oroginal File Name
										</Typography>
									</TableCell>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Downloaded Time
										</Typography>
									</TableCell>

									<TableCell align="center">
										<Typography color="textSecondary" variant="h5">
											User Based Reports
										</Typography>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{downloadLogs.map((user) => (
									<TableRow key={user.userId}>
										<TableCell>
											<Typography
												sx={{
													fontSize: "15px",
													fontWeight: "500",
													paddingLeft: "0",
												}}
											>
												{user.userName}
											</Typography>
										</TableCell>
										<TableCell>
											<Typography
												sx={{
													fontSize: "15px",
													fontWeight: "500",
													paddingLeft: "0",
												}}
											>
												{user.courseName}
											</Typography>
										</TableCell>
										<TableCell>
											<Typography
												sx={{
													fontSize: "15px",
													fontWeight: "500",
													paddingLeft: "0",
												}}
											>
												{user.originalFileName}
											</Typography>
										</TableCell>
										<TableCell>
											<Typography
												sx={{
													fontSize: "15px",
													fontWeight: "500",
													paddingLeft: "0",
												}}
											>
												{user.downloadTime}
											</Typography>
										</TableCell>

										<TableCell align="center">
											<Chip
												sx={{
													pl: "4px",
													pr: "4px",
													backgroundColor: "success.main",
													color: "#fff",
												}}
												size="small"
												label="User Report"
												onClick={() => handleDetails(user.userId)}
											/>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
						{loading ? (
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: "200px",
								}}
							>
								<CircularProgress />
							</Box>
						) : null}
						{error ? (
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: "200px",
								}}
							>
								{error}
							</Box>
						) : null}
						<Pagination
							currentPage={currentPage}
							totalPages={totalPages}
							onPageChange={handlePageChange}
						/>
					</Box>
				</CardContent>
			</Card>
		</Box>
	);
}
