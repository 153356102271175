import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import useLoading from "../../Hooks/useLoading";
import BASE_URL from "../../subcomponents/Config";

const VisitorTracker = () => {
	const { loading, showLoading, hideLoading } = useLoading();
	const [error, setError] = useState("");
	useEffect(() => {
		GetVisitor();
	}, []);

	const GetVisitor = async () => {
		const visitorId = localStorage.getItem("visitorId");
		if (!visitorId) {
			const newVisitorId = uuidv4();
			localStorage.setItem("visitorId", newVisitorId);
			showLoading();
			try {
				const response = await fetch(`${BASE_URL}/api/v1/count/increment`, {
					method: "POST",
				});
				if (!response.ok) {
					const error = await response.json();
					setError(error.message);
					console.log(error);
					hideLoading();
				} else {
					const data = await response.json();
					console.log(data);
				}
			} catch (error) {
				setError(error);
				hideLoading();
			}
		}
	};
};

export default VisitorTracker;
