import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useCookies } from "react-cookie";
import useFormValidation from "../../Hooks/useFormValidation";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../../subcomponents/Config";
import { IoArrowBackSharp } from "react-icons/io5";
import { toast, ToastContainer } from "react-toastify";
import { Box, CircularProgress } from "@mui/material";

const ChangePassword = () => {
	const nav = useNavigate();
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);

	const initialFormState = {
		oldPassword: "",
		newPassword: "",
		confirmPassword: "",
	};

	const {
		formData,
		setErrors,
		errors,
		handleChange,
		validateForm,
		setFormData,
	} = useFormValidation(initialFormState);

	const [cookies, setCookie] = useCookies(["userToken"]);
	const [token, setToken] = useState(cookies.userToken || "");

	useEffect(() => {
		window.scrollTo(0, 0);
		setToken(cookies.userToken || "");
	}, [cookies]);

	const handleSubmit = async (e) => {
		e.preventDefault();

		const validationErrors = validateForm(formData);
		console.log(errors);

		if (Object.keys(validationErrors).length === 0) {
			setLoading(true);
			console.log(formData);
			try {
				const response = await fetch(`${BASE_URL}/api/v1/change-password`, {
					method: "POST",
					headers: {
						Authorization: `Bearer ${token}`,
						"Content-Type": "application/json",
					},
					body: JSON.stringify(formData),
				});
				if (response.ok) {
					const data = await response.json();
					toast.success("Password Changed Successfully!!");
					// alert("Password Changed Successfully!!");
					setFormData(initialFormState);
					setLoading(false);
				} else {
					const error = await response.json();
					setError(error.message);
					setLoading(false);
				}
			} catch (error) {
				console.log("Fetch error:", error.message);
				setLoading(false);
			}
		} else {
			setErrors(validationErrors);
		}
	};

	const handleback = () => {
		nav(-1);
	};
	return (
		<div>
			<ToastContainer position="top-center" />
			<Navbar />
			<div className="main_container">
				<div className="back_login">
					<button onClick={handleback}>
						<IoArrowBackSharp />
					</button>
					<h3>Change Password</h3>
					<p></p>
				</div>
				<div className="loging_container">
					<form action="" onSubmit={handleSubmit}>
						<div>
							<label htmlFor="">Old Password</label>
							<br />
							<input
								type="password"
								className="logInput"
								placeholder="Enter your old password"
								name="oldPassword"
								value={formData.oldPassword}
								onChange={handleChange}
							/>
							{errors.oldPassword && (
								<span className="error">{errors.oldPassword}</span>
							)}
						</div>
						<div>
							<label htmlFor="">New Password</label>
							<br />
							<input
								type="password"
								className="logInput"
								placeholder="Enter your new password"
								name="newPassword"
								value={formData.newPassword}
								onChange={handleChange}
							/>
							{errors.newPassword && (
								<span className="error">{errors.newPassword}</span>
							)}
						</div>
						<div>
							<label htmlFor="">Confirm Password</label>
							<br />
							<input
								type="password"
								className="logInput"
								placeholder="Enter your confirm password"
								name="confirmPassword"
								value={formData.confirmPassword}
								onChange={handleChange}
							/>
							{errors.confirmPassword && (
								<span className="error">{errors.confirmPassword}</span>
							)}
						</div>
						{error && (
							<p style={{ color: "red", textAlign: "center" }}>{error}</p>
						)}
						<button>
							{loading ? (
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										height: "10px",
									}}
								>
									<CircularProgress />
								</Box>
							) : (
								<div>Submit</div>
							)}
						</button>
					</form>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default ChangePassword;
