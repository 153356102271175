import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BASE_URL from "../../subcomponents/Config";
import { useCookies } from "react-cookie";
import ExamShare from "../../pages/components/ExamShare";
import {
	Card,
	CardContent,
	Typography,
	Box,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Chip,
	CircularProgress,
	Grid,
	Button,
} from "@mui/material";
import Pagination from "../adminComponents/Pagination/Pagination";
import TruncateText from "../adminComponents/Truncate/TruncateText";
import { toast, ToastContainer } from "react-toastify";
import ConfirmDialog from "../adminComponents/DialogBox/Confirm";
import { type } from "@testing-library/user-event/dist/type";
// import { Grid } from "react-virtualized";

const tablevalue = [
	{ title: "Exam Name", value: "examName" },
	{ title: "Details", value: "details" },
	{ title: "Course Title", value: "courseTitle" },
	{ title: "Exam Type", value: "examType" },
	{ title: "Total Time", value: "totalTime" },
	{ title: "Negative Marking", value: "negativeMarkingPercent" },
	{
		title: "Free Exam",
		value: (row) => (row.free ? "True" : "False"),
	},
	{
		title: "Negative Marking",
		value: (row) => (row.free ? "True" : "False"),
	},
	{ title: "Created Date", value: "createdDate" },
];

export default function ExamDetails() {
	const nav = useNavigate();
	const { id } = useParams();
	const [exam, setExam] = useState({});
	const [questions, setQuestions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [deleteId, setDeleteId] = useState(null);
	const [deleteName, setDeleteName] = useState("");
	const [cookies] = useCookies(["adminToken"]);
	const [adminToken, setAdminToken] = useState(cookies.adminToken || "");
	useEffect(() => {
		setAdminToken(cookies.adminToken || "");
	}, [cookies]);

	useEffect(() => {
		getData();
	}, []);

	function Back() {
		nav(-1);
	}

	const getData = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${BASE_URL}/api/v1/exam/${id}/admin`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${adminToken}`,
				},
			});
			if (response.ok) {
				const apiData = await response.json();
				setExam(apiData.exam);
				setQuestions(apiData.exam.questions);
				// console.log(apiData);
				setLoading(false);
			} else {
				const error = await response.json();
				setError(error.message);
				setLoading(false);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	const handleOpenDialog = (id, name) => {
		setDialogOpen(true);
		setDeleteId(id);
		setDeleteName(name);
	};

	const handleCloseDialog = () => {
		setDialogOpen(false);
		setDeleteId(null);
		setDeleteName("");
	};

	const handleEdit = async (id) => {
		nav(`/admin/EXAM_DEPARTMENT/exam/edit/${id}`);
	};

	const handleDelete = async () => {
		setLoading(true);
		if (deleteName === "exam") {
			try {
				const response = await fetch(
					`${BASE_URL}/api/v1/exam/delete/${deleteId}`,
					{
						method: "DELETE",
						headers: {
							Authorization: `Bearer ${adminToken}`,
						},
					}
				);
				if (response.ok) {
					const apiData = await response.json();
					setLoading(false);
					handleCloseDialog();
					nav(-1);
				} else {
					const error = await response.json();
					setError(error.message);
					handleCloseDialog();
					setLoading(false);
				}
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		} else {
			try {
				const response = await fetch(
					`${BASE_URL}/api/v1/exam/question/delete/${deleteId}`,
					{
						method: "DELETE",
						headers: {
							Authorization: `Bearer ${adminToken}`,
						},
					}
				);
				if (response.ok) {
					const apiData = await response.json();
					setLoading(false);
					handleCloseDialog();
					toast.success(apiData.message);
					getData();
				} else {
					const error = await response.json();
					setError(error.message);
					handleCloseDialog();
					setLoading(false);
				}
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		}
	};

	return (
		<Box>
			<Button
				onClick={() => Back()}
				variant="outlined"
				color="secondary"
				style={{ marginBottom: "0.2rem" }}
			>
				Back
			</Button>
			<Card variant="outlined">
				<CardContent>
					<Box style={{ display: "flex", justifyContent: "space-between" }}>
						<Typography variant="h3">Exam Details</Typography>
						<Box>
							<Chip
								sx={{
									pl: "4px",
									pr: "4px",
									mr: "10px",
									backgroundColor: "primary.main",
									color: "#fff",
									fontSize: "0.9rem",
								}}
								size="medium"
								label="Edit"
								onClick={() => handleEdit(id)}
							/>
							<Chip
								sx={{
									pl: "4px",
									pr: "4px",
									backgroundColor: "error.main",
									color: "#fff",
									fontSize: "0.9rem",
								}}
								size="medium"
								label="Delete"
								onClick={() => handleOpenDialog(id, "exam")}
							/>
						</Box>
					</Box>
					<Box
						sx={{
							xs: "auto",
							sm: "unset",
							overflowX: "auto",
						}}
					>
						<Table
							aria-label="simple table"
							sx={{
								mt: 3,
							}}
						>
							{!loading && exam ? (
								<>
									<TableBody>
										{tablevalue &&
											tablevalue.map((table, index) => (
												<TableRow key={index}>
													<TableCell>
														<Typography
															sx={{
																fontSize: "15px",
																fontWeight: "500",
															}}
														>
															{table.title}
														</Typography>
													</TableCell>
													<TableCell>
														<Typography
															sx={{
																fontSize: "15px",
																fontWeight: "500",
															}}
														>
															{/* {exam[table.value]} */}
															{typeof table.value === "function"
																? table.value(exam) // Call function if it exists
																: table.value === "negativeMarkingPercent"
																	? `${exam[table.value]}%` // Append '%' for negativeMarkingPercent
																	: exam[table.value]}
														</Typography>
													</TableCell>
												</TableRow>
											))
										}
										<TableRow>
											<TableCell>
												<Typography
													sx={{
														fontSize: "15px",
														fontWeight: "500",
													}}
												>
													Image
												</Typography>
											</TableCell>
											<TableCell>
												<Typography
													sx={{
														fontSize: "15px",
														fontWeight: "500",
													}}
												>
													<img
														src={exam.imageUrl}
														alt=""
														style={{
															width: "15rem",
															height: "auto",
														}}
													/>
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow>
											<Typography
														sx={{
															fontSize: "15px",
															fontWeight: "500",
															paddingTop: "1.5rem",
														}}
													>
														Share exam
											</Typography>
											<TableCell>
													<ExamShare type={exam.examType}  style={{ margin: '10px' }} />
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>
												<Typography color="textSecondary" variant="h5">
													QuestionId
												</Typography>
											</TableCell>
											<TableCell>
												<Typography color="textSecondary" variant="h5">
													Question Details
												</Typography>
											</TableCell>

											<TableCell>
												<Typography color="textSecondary" variant="h5">
													Question Image
												</Typography>
											</TableCell>

											<TableCell>
												<Typography color="textSecondary" variant="h5">
													Created Date
												</Typography>
											</TableCell>
											<TableCell align="center">
												<Typography color="textSecondary" variant="h5">
													Delete
												</Typography>
											</TableCell>
										</TableRow>
										{questions &&
											questions.map((data, index) => (
												<TableRow key={index}>
													<TableCell align="center">
														<Typography>{data.questionId}</Typography>
													</TableCell>

													<TableCell align="left">
														<Typography sx={{ paddingLeft: "0" }}>
															<Box>Question: {data.question}</Box>
															<Box>Option 1: {data.option1}</Box>
															<Box>Option 2: {data.option2}</Box>
															<Box>Option 3: {data.option3}</Box>
															<Box>Option 4: {data.option4}</Box>
															<Box>Correct Answer: {data.correctAnswer}</Box>
														</Typography>
													</TableCell>



													<TableCell align="left" sx={{ fontSize: "15px", fontWeight: "500" }}>
														{data.questionImageUrl ? (
															<img
																src={data.questionImageUrl}
																alt="Question"
																style={{ maxWidth: "200px", maxHeight: "200px" }}
															/>
														) : (
															<Typography sx={{ paddingLeft: "0" }}>No Question Image</Typography>
														)}
													</TableCell>

													<TableCell
														align="left"
														sx={{ fontSize: "15px", fontWeight: "500" }}
													>
														<Typography sx={{ paddingLeft: "0" }}>
															{data.createdDate}
														</Typography>
													</TableCell>
													<TableCell>
														<TableCell align="center">
															<Chip
																sx={{
																	pl: "4px",
																	pr: "4px",
																	backgroundColor: "error.main",
																	color: "#fff",
																}}
																size="small"
																label="Delete"
																onClick={() =>
																	handleOpenDialog(data.questionId, "question")
																}
															/>
														</TableCell>
														<ConfirmDialog
															open={dialogOpen}
															title="Confirm Action"
															content="Are you sure you want to delete this?"
															onClose={() => handleCloseDialog()}
															onConfirm={() => handleDelete()}
														/>
													</TableCell>
												</TableRow>
											))}
									</TableBody>
								</>
							) : (
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										height: "200px",
									}}
								>
									<CircularProgress />
								</Box>
							)}
							{error ? (
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										height: "200px",
									}}
								>
									{error}
								</Box>
							) : null}
						</Table>
					</Box>
				</CardContent>
			</Card>
		</Box>
	);
}
