import React, { useEffect, useState } from "react";
import BASE_URL from "../../subcomponents/Config";
import { useCookies } from "react-cookie";
import { Box, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../../Styles/Courses/Mycourses.scss";
import Loading from "../components/Loading";
import useLoading from "../../Hooks/useLoading";

const MyCourses = () => {
	const nav = useNavigate();
	const { loading, showLoading, hideLoading } = useLoading();
	const [courses, setCourses] = useState([]);

	const [cookies] = useCookies(["userToken", "userId", "userRole"]);
	const [token, setToken] = useState(cookies.userToken || "");
	const [id, setId] = useState(cookies.userId || "");
	const [role, setRole] = useState(cookies.userRole || "");

	useEffect(() => {
		setToken(cookies.userToken || "");
		setId(cookies.userId || "");
		setRole(cookies.userRole || "");
	}, [cookies]);

	useEffect(() => {
		window.scrollTo(0, 0);
		MyCourses();
	}, []);

	const MyCourses = async () => {
		showLoading();
		try {
			const response = await fetch(
				`${BASE_URL}/api/v1/course/enrolledCourses/${id}`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${token}`,
						mode: "cors",
					},
				}
			);
			if (response.ok) {
				const getApi = await response.json();
				setCourses(getApi.courses);
				hideLoading();
			} else {
				const error = await response.json();
				hideLoading();
			}
		} catch (error) {
			console.error("Error fetching data.", error);
		}
	};

	const ShowVacancyDetails = (id) => {
		nav(`/course/myCourseDetails/${id}`);
		window.location.reload();
	};

	return (
		<>
			<Navbar />
			{loading ? <Loading /> : null}
			{!loading && (
				<main>
					<div className="mycourse_container">
						<h3>My Enrolled Courses</h3>
						{role !== "SUBSCRIBED" ? (
							<div className="not_sub">
								<h5>You are not enrolled into any courses !!</h5>
							</div>
						) : (
							<div>
								<div className="grid_mycourse">
									{courses &&
										courses.map((data, index) => (
											<div className="mycourse" key={index}>
												<div className="my_img">
													<img src={data.imageUrl} alt="" />
												</div>
												<div className="course_des">
													<h4>{data.title}</h4>
													<div className="myCourse_desription">
														{data.details}
													</div>
													<div className="btn_bottom">
														<button onClick={() => ShowVacancyDetails(data.id)}>
															About Course
														</button>
													</div>
												</div>
											</div>
										))}
								</div>
							</div>
						)}
					</div>
				</main>
			)}
			<Footer />
		</>
	);
};

export default MyCourses;
