// ExamEdit.js

import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useCookies } from "react-cookie";
import BASE_URL from "../../subcomponents/Config";
import {
	TextField,
	Button,
	Typography,
	Container,
	Box,
	IconButton,
} from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { toast, ToastContainer } from "react-toastify";
import CourseSelect from "../adminComponents/Api/CourseSelect";
import { useNavigate, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import useQuestionFormValidation from "../../Hooks/useQuestionFormValidation";

const AdminLoginForm = styled("form")({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	border: "1px solid #ccc",
	padding: "20px",
	borderRadius: "5px",
});

const initialFormState = {
	name: "",
	courseId: "",
	questions: [
		{
			questionId: null,
			question: "",
			option1: "",
			option2: "",
			option3: "",
			option4: "",
			correctAnswer: "",
			questionImageFile: null,
		},
	],
};

const QuestionBankEdit = () => {
	const { id } = useParams();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [cookies] = useCookies(["adminToken", "adminRoles"]);
	const [adminToken, setAdminToken] = useState(cookies.adminToken || "");
	const nav = useNavigate();

	const {
		formData,
		setFormData,
		setErrors,
		errors,
		handleChange,
		validateForm,
		handleQuestionChange,
		currentQuestionIndex,
		setCurrentQuestionIndex,
	} = useQuestionFormValidation(initialFormState);

	useEffect(() => {
		setAdminToken(cookies.adminToken || "");
	}, [cookies]);

	const handleSelectCourse = (courseId) => {
		setFormData({ ...formData, courseId });

		setErrors((prevErrors) => ({
			...prevErrors,
			courseId: "",
		}));
	};

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		setLoading(true);
		try {
			const response = await fetch(
				`${BASE_URL}/api/v1/questionBank/detail/${id}`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${adminToken}`,
						mode: "cors",
					},
				}
			);
			if (response.ok) {
				const apiData = await response.json();
				setFormData(apiData.questionBank);
				setLoading(false);
			} else {
				const error = await response.json();
				setError(error.message);
				setLoading(false);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const validationErrors = validateForm(formData);

		if (Object.keys(validationErrors).length === 0) {
			setLoading(true);

			const formDataToSubmit = new FormData();
			formDataToSubmit.append("name", formData.name);
			formDataToSubmit.append("courseId", formData.courseId);

			formData.questions.forEach((question, index) => {
				if (question.questionId) {
					formDataToSubmit.append(
						`questions[${index}].questionId`,
						question.questionId
					);
				}
				formDataToSubmit.append(
					`questions[${index}].question`,
					question.question
				);
				formDataToSubmit.append(
					`questions[${index}].option1`,
					question.option1
				);
				formDataToSubmit.append(
					`questions[${index}].option2`,
					question.option2
				);
				formDataToSubmit.append(
					`questions[${index}].option3`,
					question.option3
				);
				formDataToSubmit.append(
					`questions[${index}].option4`,
					question.option4
				);
				formDataToSubmit.append(
					`questions[${index}].correctAnswer`,
					question.correctAnswer
				);
				if (question.questionImageFile) {
					formDataToSubmit.append(
						`questions[${index}].questionImageFile`,
						question.questionImageFile
					);
				}
			});

			try {
				const response = await fetch(
					`${BASE_URL}/api/v1/questionBank/update/${id}`,
					{
						method: "PUT",
						headers: {
							Authorization: `Bearer ${adminToken}`,
						},
						body: formDataToSubmit,
					}
				);

				if (response.ok) {
					const courseData = await response.json();

					setError("");
					toast.success(courseData.message);
					setLoading(false);
				} else {
					const error = await response.json();
					setError(error.message);
					setLoading(false);
				}
			} catch (error) {
				console.log(error.message);
			}
		} else {
			setErrors(validationErrors);
		}
	};

	const handleRemoveQuestion = (index) => {
		if (formData.questions.length > 1) {
			const updatedQuestions = [...formData.questions];

			updatedQuestions.splice(index, 1);

			setFormData({ ...formData, questions: updatedQuestions });

			setCurrentQuestionIndex(
				Math.min(currentQuestionIndex, updatedQuestions.length - 1)
			);
		} else {
			setFormData({
				...formData,
				questions: [
					{
						questionId: null,
						question: "",
						option1: "",
						option2: "",
						option3: "",
						option4: "",
						correctAnswer: "",
					},
				],
			});
		}
		setErrors({});
	};

	const addNewQuestion = () => {
		setFormData({
			...formData,
			questions: [
				...formData.questions,
				{
					question: "",
					option1: "",
					option2: "",
					option3: "",
					option4: "",
					correctAnswer: "",
				},
			],
		});
		setCurrentQuestionIndex(formData.questions.length);
	};

	const handlePreviousQuestion = () => {
		if (currentQuestionIndex > 0) {
			setCurrentQuestionIndex(currentQuestionIndex - 1);
			setErrors({});
		}
	};

	const handleNextQuestion = () => {
		if (currentQuestionIndex < formData.questions.length - 1) {
			setCurrentQuestionIndex(currentQuestionIndex + 1);
			setErrors({});
		}
	};

	function Back() {
		nav(-1);
	}

	return (
		<>
			<ToastContainer position="top-center" autoClose="2000" />
			<Container maxWidth="xs">
				<Button
					onClick={() => Back()}
					variant="outlined"
					color="secondary"
					style={{ marginBottom: "0.2rem" }}
				>
					Back
				</Button>
				<AdminLoginForm onSubmit={handleSubmit}>
					<Typography variant="h2" gutterBottom>
						Edit Question Bank
					</Typography>
					{error && (
						<Typography variant="subtitle2" color="error" gutterBottom>
							{error}!!
						</Typography>
					)}
					<Box width="100%" marginBottom="20px">
						<TextField
							label="Name"
							variant="outlined"
							name="name"
							fullWidth
							value={formData.name}
							onChange={handleChange}
						/>
						{errors.name && <span style={{ color: "red" }}>{errors.name}</span>}
					</Box>
					<Box width="100%" marginBottom="20px">
						<CourseSelect
							selectedCourseId={formData.courseId}
							onSelectCourse={handleSelectCourse}
							BASE_URL={BASE_URL}
						/>
						{errors.courseId && (
							<span style={{ color: "red" }}>{errors.courseId}</span>
						)}
					</Box>

					<Box width="100%" marginBottom="20px">
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<Typography variant="h6" gutterBottom>
								Question {currentQuestionIndex + 1} of{" "}
								{formData.questions.length}
							</Typography>
							<IconButton
								aria-label="delete"
								onClick={() => handleRemoveQuestion(currentQuestionIndex)}
							>
								<CloseIcon />
							</IconButton>
						</div>
						<TextField
							label="Question"
							variant="outlined"
							name={`question`}
							fullWidth
							value={formData.questions[currentQuestionIndex].question}
							onChange={(e) => handleQuestionChange("question", e.target.value)}
						/>
						{errors[`question`] && (
							<span style={{ color: "red" }}>{errors[`question`]}</span>
						)}
					</Box>
					<Box width="100%" marginBottom="10px">
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								border: "1px solid #ccc",
								padding: "0.4rem",
								borderRadius: "5px",
							}}
						>
							<Typography
								variant="body1"
								sx={{ flexGrow: 1, color: "gray", padding: 0 }}
							>
								Question Image(optional)
							</Typography>
							<input
								id="question-upload"
								type="file"
								onChange={(e) => {
									const file = e.target.files[0];
									if (file) {
										handleQuestionChange("questionImageFile", file);
									}
								}}
								accept="image/*"
								style={{ display: "none" }}
							/>
							{formData.questions[currentQuestionIndex].questionImageFile && (
								<Typography
									variant="body2"
									sx={{
										marginTop: "10px",
										color: "gray",
										margin: "1px",
										// padding: 0,
									}}
								>
									{
										formData.questions[currentQuestionIndex].questionImageFile
											.name
									}
								</Typography>
							)}
							<label htmlFor="question-upload">
								<Button variant="outlined" component="span">
									Select
								</Button>
							</label>
						</Box>
					</Box>
					<div style={{ display: "flex" }}>
						<Box width="100%" marginBottom="20px" marginRight="10px">
							<TextField
								label="Option 1"
								variant="outlined"
								name={`option1`}
								fullWidth
								value={formData.questions[currentQuestionIndex].option1}
								onChange={(e) =>
									handleQuestionChange("option1", e.target.value)
								}
							/>
							{errors[`option1`] && (
								<span style={{ color: "red" }}>{errors[`option1`]}</span>
							)}
						</Box>
						<Box width="100%" marginBottom="20px">
							<TextField
								label="Option 2"
								variant="outlined"
								name={`option2`}
								fullWidth
								value={formData.questions[currentQuestionIndex].option2}
								onChange={(e) =>
									handleQuestionChange("option2", e.target.value)
								}
							/>
							{errors[`option2`] && (
								<span style={{ color: "red" }}>{errors[`option2`]}</span>
							)}
						</Box>
					</div>
					<div style={{ display: "flex" }}>
						<Box width="100%" marginBottom="20px" marginRight="10px">
							<TextField
								label="Option 3"
								variant="outlined"
								name={`option3`}
								fullWidth
								value={formData.questions[currentQuestionIndex].option3}
								onChange={(e) =>
									handleQuestionChange("option3", e.target.value)
								}
							/>
							{errors[`option3`] && (
								<span style={{ color: "red" }}>{errors[`option3`]}</span>
							)}
						</Box>
						<Box width="100%" marginBottom="20px">
							<TextField
								label="Option 4"
								variant="outlined"
								name={`option4`}
								fullWidth
								value={formData.questions[currentQuestionIndex].option4}
								onChange={(e) =>
									handleQuestionChange("option4", e.target.value)
								}
							/>
							{errors[`option4`] && (
								<span style={{ color: "red" }}>{errors[`option4`]}</span>
							)}
						</Box>
					</div>
					<Box width="100%" marginBottom="20px">
						<TextField
							label="Correct Answer"
							variant="outlined"
							name={`correctAnswer`}
							fullWidth
							value={formData.questions[currentQuestionIndex].correctAnswer}
							onChange={(e) =>
								handleQuestionChange("correctAnswer", e.target.value)
							}
						/>
						{errors[`correctAnswer`] && (
							<span style={{ color: "red" }}>{errors[`correctAnswer`]}</span>
						)}
					</Box>

					{/* Navigation Buttons */}
					<Box
						width="100%"
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						marginBottom="20px"
					>
						<IconButton
							color="primary"
							onClick={handlePreviousQuestion}
							disabled={currentQuestionIndex === 0}
						>
							<ArrowBack />
						</IconButton>
						<Button
							variant="outlined"
							color="secondary"
							disabled={loading}
							onClick={addNewQuestion}
							style={{ marginBottom: "20px" }}
						>
							Add More Questions
						</Button>
						<IconButton
							color="primary"
							onClick={handleNextQuestion}
							disabled={currentQuestionIndex === formData.questions.length - 1}
						>
							<ArrowForward />
						</IconButton>
					</Box>

					<Button
						type="submit"
						variant="contained"
						color="secondary"
						disabled={loading}
					>
						Edit Question Bank
					</Button>
				</AdminLoginForm>
			</Container>
		</>
	);
};

export default QuestionBankEdit;
