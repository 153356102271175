import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
	Card,
	CardContent,
	Typography,
	Box,
	Table,
	TableBody,
	TableCell,
	TableRow,
	CircularProgress,
	Button,
    TableHead,
} from "@mui/material";
import BASE_URL from "../../subcomponents/Config.jsx";

export default function DownloadedLogDetails() {
	const { id } = useParams();
	const [userDownloadStats, setUserDownloadStats] = useState({});
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [cookies] = useCookies(["adminToken"]);
	const nav = useNavigate();
	const [adminToken, setAdminToken] = useState(cookies.adminToken || "");

	useEffect(() => {
		setAdminToken(cookies.adminToken || "");
	}, [cookies]);

	function Back() {
		nav(-1);
	}

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${BASE_URL}/api/v1/videoLog/user/${id}`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${adminToken}`,
				},
			});
			if (response.ok) {
				const apiData = await response.json();
				setUserDownloadStats(apiData.userDownloadStats);
				setLoading(false);
			} else {
				const error = await response.json();
				setError(error.message);
				setLoading(false);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
			setError("An error occurred while fetching data.");
			setLoading(false);
		}
	};

	return (
		<Box>
			<Button
				onClick={() => Back()}
				variant="outlined"
				color="secondary"
				style={{ marginBottom: "0.2rem", marginLeft: "1.5rem" }}
			>
				Back
			</Button>
			<Card variant="outlined">
				<CardContent>
					<Typography variant="h3">User Based Reports</Typography>
                        {userDownloadStats && userDownloadStats.userName && (
                            <Box sx={{ display: "flex", justifyContent:"space-between", marginTop: "1rem", gap: "1rem" }}>

                                <Box> <Typography>User Name</Typography>{userDownloadStats.userName}</Box>
                                <Box><Typography>Video Download Count</Typography>{userDownloadStats.videoDownloadCount}</Box>
                            </Box>
                        )}
					<Box
						sx={{
							overflow: {
								xs: "auto",
								sm: "unset",
							},
						}}
					>
						<Table
							aria-label="simple table"
							sx={{
								mt: 3,
							}}
						>
                            <TableHead>
								<TableRow>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
                                        Course Title
										</Typography>
									</TableCell>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
                                        Uploaded File Name
										</Typography>
									</TableCell>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
                                        Original File Name
										</Typography>
									</TableCell>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
                                        Download Time
										</Typography>
									</TableCell>
								</TableRow>
							</TableHead>
                            {!loading && userDownloadStats.downloadedInfo ? (
                                <TableBody>
                                    {userDownloadStats.downloadedInfo.map((table, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <Typography
                                                sx={{
                                                fontSize: "15px",
                                                fontWeight: "500",
                                                paddingLeft: "0",
                                                }}
                                            >
                                                {table.courseName}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                sx={{
                                                fontSize: "15px",
                                                fontWeight: "500",
                                                paddingLeft: "0",
                                                }}
                                            >
                                                {table.uploadedFileName}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                sx={{
                                                fontSize: "15px",
                                                fontWeight: "500",
                                                paddingLeft: "0",
                                                }}
                                            >
                                                {table.originalFileName}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                sx={{
                                                fontSize: "15px",
                                                fontWeight: "500",
                                                paddingLeft: "0",
                                                }}
                                            >
                                                {table.downloadTime}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    ))}
                                </TableBody>
                            ) : (
                                <Box
                                    sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "200px",
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            )}

							{error && (
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										height: "200px",
									}}
								>
									{error}
								</Box>
							)}
						</Table>
					</Box>
				</CardContent>
			</Card>
		</Box>
	);
}
