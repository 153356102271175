import React, { useState } from "react";
import "../../Styles/Components/Footer.scss";
import { NavLink } from "react-router-dom";

import { FaFacebookF } from "react-icons/fa6";
import { SlSocialTwitter } from "react-icons/sl";
import { FaInstagram } from "react-icons/fa";
import { TfiLinkedin } from "react-icons/tfi";
import { BsTwitterX } from "react-icons/bs";

import apple from "../components/Images/Rectangle 38.png";
import google from "../components/Images/Rectangle 37.png";

const Footer = () => {
	const [examMenu, setExamMenu] = useState(false);
	const [isMobile, setIsMobile] = useState(false);

	return (
		<div className="footer">
			<div className="qick_link">
				<h3>Quick Links</h3>
				<ul>
					<li>
						<NavLink to="/">Home</NavLink>
					</li>
					<li>
						<NavLink to="/course">Courses</NavLink>
					</li>
					<li>
						<NavLink to="/article">Articles</NavLink>
					</li>
					{/* <li>
						<NavLink to="/exam">Exam</NavLink>
					</li> */}
					<li
						className="exam-menu"
						onMouseEnter={() => setExamMenu(true)}
						onMouseLeave={() => setExamMenu(false)}
					>
						<span>Exam</span>
						{examMenu && (
							<ul className="exam-dropdown">
								<li
									onClick={() => {
										setIsMobile(false);
									}}
								>
									<NavLink to="/exam/daily">Daily</NavLink>
								</li>
								<li
									onClick={() => {
										setIsMobile(false);
									}}
								>
									<NavLink to="/exam/weekly">Weekly</NavLink>
								</li>
								<li
									onClick={() => {
										setIsMobile(false);
									}}
								>
									<NavLink to="/exam/free">Free</NavLink>
								</li>
							</ul>
						)}
					</li>
					{/* <li><NavLink to="/videos">Videos</NavLink></li> */}
					<li>
						<NavLink to="/vacancy">Vacancies</NavLink>
					</li>
					<li>
						<NavLink to="/about">About Us</NavLink>
					</li>
					<li>
						<NavLink to="/contact">Contact Us</NavLink>
					</li>
				</ul>
			</div>
			<hr className="vrticle_row" />
			<div className="find_us">
				<h3>Find Us On</h3>
				<div className="inner_element facebook">
					<NavLink to="">
						<span className="fbk">
							<FaFacebookF />
						</span>
						Facebook
					</NavLink>
				</div>
				<div className="inner_element tweeter">
					<NavLink to="">
						<span className="twt">
							<BsTwitterX />
						</span>
						X
					</NavLink>
				</div>
				<div className="inner_element linkedIn">
					<NavLink to="">
						<span className="lnd">
							<TfiLinkedin />
						</span>
						Linkedin
					</NavLink>
				</div>
				<div className="inner_element insta">
					<NavLink to="">
						<span className="ins">
							<FaInstagram />
						</span>
						Instagram
					</NavLink>
				</div>
			</div>
			<hr className="vrticle_row" />
			<div className="download_app">
				<h3>Download Our App</h3>
				<div className="img_flex">
					<div className="image1">
						<NavLink to="">
							<img src={apple} alt="" />
						</NavLink>
					</div>
					<div className="image2">
						<NavLink to="">
							<img src={google} alt="" />
						</NavLink>
					</div>
				</div>
				<div className="footer-powered">Powered by Envision Tech Pvt. Ltd</div>
			</div>
		</div>
	);
};

export default Footer;
