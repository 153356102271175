import React, { useEffect, useState } from "react";
import BASE_URL from "../../subcomponents/Config";
import { useCookies } from "react-cookie";
import { Box, CircularProgress, TextField } from "@mui/material";
import "./Dashboard.scss";
import { FaBookOpen } from "react-icons/fa6";
import { FaBookMedical } from "react-icons/fa";
import { FaUserGraduate } from "react-icons/fa6";
import { FaUsers } from "react-icons/fa6";
import { FaUserCheck } from "react-icons/fa";

const Dashboard = () => {
	const [loading, setLoading] = useState(true);
	const [dashboard, setDashboard] = useState({});
	const [error, setError] = useState(null);
	const [dashboardReport, setDashboardReport] = useState({});

	const [cookies] = useCookies(["adminToken"]);
	const adminToken = cookies.adminToken || "";

	const [timeRange, setTimeRange] = useState("weekly");

	const capitalizeFirstLetter = (string) => {
		return string.charAt(0).toUpperCase() + string.slice(1);
	};
	const capitalizedTimeRange = timeRange
		? capitalizeFirstLetter(timeRange)
		: "";

	// Get the current date in YYYY-MM-DD format
	const getCurrentDate = () => {
		const today = new Date();
		const year = today.getFullYear();
		const month = String(today.getMonth() + 1).padStart(2, "0");
		const day = String(today.getDate()).padStart(2, "0");
		return `${year}-${month}-${day}`;
	};

	const [selectedDate, setSelectedDate] = useState(getCurrentDate());

	useEffect(() => {
		getData();
	}, [selectedDate, timeRange]);

	const getData = async () => {
		setLoading(true);
		try {
			const response = await fetch(
				`${BASE_URL}/api/v1/dashboard/date?reportType=${timeRange}&date=${selectedDate}`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${adminToken}`,
					},
				}
			);
			if (response.ok) {
				const apiData = await response.json();
				setDashboardReport(apiData.dashboardReport);
				setLoading(false);
			} else {
				const error = await response.json();
				setError(error.message);
				setLoading(false);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
			setLoading(false);
		}
	};

	const handleDateChange = (e) => {
		setSelectedDate(e.target.value);
	};

	const handleTimeRangeChange = (newTimeRange) => {
		setTimeRange(newTimeRange);
	};

	// Dashboard info data from here
	useEffect(() => {
		const fetchCourses = async () => {
			try {
				const response = await fetch(
					`${BASE_URL}/api/v1/dashboard/allTimeCount`,
					{
						method: "GET",
						headers: {
							Authorization: `Bearer ${adminToken}`,
						},
					}
				);
				if (response.ok) {
					const data = await response.json();
					setDashboard(data.dashboard);
				} else {
					console.error("Failed to fetch dashboard data.");
				}
			} catch (error) {
				console.error("Error fetching data.", error);
			} finally {
				setLoading(false);
			}
		};
		fetchCourses();
	}, [cookies]);

	return (
		<>
			{loading ? (
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "80vh",
					}}
				>
					<CircularProgress />
				</Box>
			) : (
				<div className="main_dashboard">
					<div className="heading">Welcome to the Super Admin Dashboard !!</div>
					<div className="subHeading">All time data</div>

					{dashboard && (
						<div className="main_box">
							<div className="box">
								<div className="icon">
									<FaUsers />
								</div>
								<div className="box_heading">Total Visitors</div>
								<div className="box_data">{dashboard.visitorCount}</div>
							</div>
							<div className="box">
								<div className="icon">
									<FaUserCheck />
								</div>
								<div className="box_heading">Registered Users</div>
								<div className="box_data">{dashboard.registeredUser}</div>
							</div>
							<div className="box">
								<div className="icon">
									<FaUserGraduate />
								</div>
								<div className="box_heading">Enrolled Students</div>
								<div className="box_data">{dashboard.enrolledUser}</div>
							</div>
							<div className="box">
								<div className="icon">
									<FaBookMedical />
								</div>
								<div className="box_heading">Course Counts</div>
								<div className="box_data">{dashboard.courseCount}</div>
							</div>
							<div className="box">
								<div className="icon">
									<FaBookOpen />
								</div>
								<div className="box_heading">Article Counts</div>
								<div className="box_data">{dashboard.articleCount}</div>
							</div>
						</div>
					)}

					{
						<div className="MainButtom">
							<div className="subHeading">Filter Data</div>
							<div className="upperPart">
								<div>
									<TextField
										label="Select Duration Date"
										variant="outlined"
										name="selectDuration"
										fullWidth
										type="date"
										value={selectedDate}
										onChange={handleDateChange}
										InputLabelProps={{
											shrink: true,
										}}
									/>
								</div>
								<div className="titleFlex">
									<div
										className={`weekly ${
											timeRange === "weekly" ? "active" : ""
										}`}
										onClick={() => handleTimeRangeChange("weekly")}
									>
										Weekly
									</div>
									<div
										className={`monthly ${
											timeRange === "monthly" ? "active" : ""
										}`}
										onClick={() => handleTimeRangeChange("monthly")}
									>
										Monthly
									</div>
									<div
										className={`yearly ${
											timeRange === "yearly" ? "active" : ""
										}`}
										onClick={() => handleTimeRangeChange("yearly")}
									>
										Yearly
									</div>
								</div>
							</div>
							{dashboardReport && Object.keys(dashboardReport).length > 0 && (
								<div className="main_box1">
									<div className="box">
										<div className="icon">
											<FaUserCheck />
										</div>
										<div className="box_heading">Registered Users</div>
										<div className="box_data">
											{dashboardReport.registeredUser}
										</div>
									</div>
									<div className="box">
										<div className="icon">
											<FaUserGraduate />
										</div>
										<div className="box_heading">Enrolled Students</div>
										<div className="box_data">
											{dashboardReport.enrolledUser}
										</div>
									</div>
									<div className="box">
										<div className="icon">
											<FaBookMedical />
										</div>
										<div className="box_heading">Course Counts</div>
										<div className="box_data">
											{dashboardReport.courseCount}
										</div>
									</div>
									<div className="box">
										<div className="icon">
											<FaBookOpen />
										</div>
										<div className="box_heading">Article Counts</div>
										<div className="box_data">
											{dashboardReport.articleCount}
										</div>
									</div>
								</div>
							)}
						</div>
					}
				</div>
			)}
		</>
	);
};

export default Dashboard;
