import React from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import BackImg from "./components/Images/envision tech.png";
import "../Styles/about.scss";
import Testimonial from "./components/Testimonial";

const About = () => {
  return (
    <>
    <Navbar />
    {/* <main> */}
      <div className="container">

        <div className="image">
          {/* <img src={BackImg} alt="" /> */}
        </div>

        <div className="about">
          <h4 className="about-h4">About Us</h4>
          <h5 className="about-h5">Envision Tech</h5>
          <p>
          Welcome to Envision Tech, where innovation meets expertise in 
          the world of Information Technology. At Envision Tech, we are 
          passionate about leveraging technology to empower businesses and 
          individuals to achieve their goals and aspirations. Our mission at 
          Envision Tech is to deliver exceptional IT solutions that drive 
          business growth, enhance efficiency, and foster innovation. We strive to 
          be a trusted partner for our clients, providing them with the tools and 
          support they need to thrive in today's digital landscape.
          </p>
        </div>
      </div>

      <div className="features">
        <h4 className="features-heading">Our Features</h4>
        <div className="bottom">
          <p className="features-p">Videos recorded classes</p>
          <span>Provide video-recorded classes and many more</span>
        </div>
        <div className="center">
          <p className="features-p">Subject Expert Teacher</p>
          <span>Provide education Nepal's expert teachers</span>
        </div>
        <div className="side">
          <p className="features-p">Daily live classes, Exams</p>
          <span>Conduct online full model set exam daily</span>
        </div>
      </div>

    {/* </main> */}
    <Testimonial />
    <Footer />
    </>
  );
};

export default About;
