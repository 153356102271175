import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BASE_URL from "../../subcomponents/Config";
import { useCookies } from "react-cookie";
import {
	Card,
	CardContent,
	Typography,
	Box,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	CircularProgress,
	Button,
	TextField,
} from "@mui/material";
import ExportToExcel from "../adminComponents/DownloadExcel/ExcelDownload";
import { Column } from "react-virtualized";

export default function SelectDuration() {
	const nav = useNavigate();
	const { id, timeRange } = useParams();

	const [courseReport, setcourseReport] = useState({});
	const [enrolledUsers, setenrolledUsers] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	
	const [cookies] = useCookies(["adminToken"]);
	const [adminToken, setAdminToken] = useState(cookies.adminToken || "");
	const capitalizeFirstLetter = (string) => {
		return string.charAt(0).toUpperCase() + string.slice(1);
	};
	const capitalizedTimeRange = capitalizeFirstLetter(timeRange);

	// Get the current date in YYYY-MM-DD format
	const getCurrentDate = () => {
		const today = new Date();
		const year = today.getFullYear();
		const month = String(today.getMonth() + 1).padStart(2, "0"); // Ensure two digits
		const day = String(today.getDate()).padStart(2, "0"); // Ensure two digits
		return `${year}-${month}-${day}`;
	};

	const [selectedDate, setSelectedDate] = useState(getCurrentDate()); // Set default to current date

	useEffect(() => {
		setAdminToken(cookies.adminToken || "");
	}, [cookies]);

	useEffect(() => {
		getData();
	}, [selectedDate]); // Fetch data whenever the date changes

	const getData = async () => {
		setLoading(true);
		try {
			const response = await fetch(
				`${BASE_URL}/api/v1/account/courseReport/date?reportType=${timeRange}&date=${selectedDate}&courseId=${id}`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${adminToken}`,
					},
				}
			);
			if (response.ok) {
				const apiData = await response.json();
				setenrolledUsers(apiData.courseReport.enrolledUsers);
				setcourseReport(apiData.courseReport);
				setLoading(false);
			} else {
				const error = await response.json();
				setError(error.message);
				setLoading(false);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
			setLoading(false);
		}
	};

	const handleDateChange = (e) => {
		setSelectedDate(e.target.value); // Update the date when user changes it
	};

	function Back() {
		nav(-1);
	}

	return (
		<Box>
			<Button
				onClick={() => Back()}
				variant="outlined"
				color="secondary"
				style={{ marginBottom: "0.2rem", marginLeft: "1.15rem" }}
			>
				Back
			</Button>
			<Card variant="outlined">
				<CardContent>
					<Box sx={{ display: "flex", justifyContent: "space-between" }}>
						<Typography variant="h3">
							{capitalizedTimeRange} Enrollment Reports
						</Typography>
						{enrolledUsers.length !== 0 && (
							<ExportToExcel data={enrolledUsers} />
						)}
					</Box>
					<Box
						sx={{
							overflow: {
								xs: "auto",
								sm: "unset",
							},
						}}
					>
						<Table>
							<TableRow>
								<TableCell>
									<Typography
										sx={{
											fontSize: "18px",
											fontWeight: "500",
											paddingLeft: "0",
											textAlign: "left",
										}}
									>
										Course Title: {courseReport.courseTitle}
									</Typography>
								</TableCell>
								<TableCell>
									<TextField
										label="Select Duration Date"
										variant="outlined"
										name="selectDuration"
										fullWidth
										type="date"
										value={selectedDate} // Bind to selectedDate state
										onChange={handleDateChange} // Handle date change
										InputLabelProps={{
											shrink: true,
										}}
									/>
								</TableCell>
							</TableRow>
						</Table>

						<Table
							aria-label="simple table"
							sx={{
								mt: 3,
							}}
						>
							<TableHead>
								<TableRow>
									<TableCell sx={{ width: "10px" }}>
										<Typography color="textSecondary" variant="h5">
											S.N.
										</Typography>
									</TableCell>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Full Name
										</Typography>
									</TableCell>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Mobile Number
										</Typography>
									</TableCell>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Email
										</Typography>
									</TableCell>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Enrolled Date
										</Typography>
									</TableCell>
									<TableCell>
										<Typography color="textSecondary" variant="h5">
											Amount Paid
										</Typography>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{enrolledUsers &&
									enrolledUsers.map((report, index) => (
										<TableRow key={index}>
											<TableCell>
												<Typography
													sx={{
														fontSize: "15px",
														fontWeight: "500",
														paddingLeft: "0",
													}}
												>
													{index + 1}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography
													sx={{
														fontSize: "15px",
														fontWeight: "500",
														paddingLeft: "0",
													}}
												>
													{report.firstName} {report.lastName}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography
													sx={{
														fontSize: "15px",
														fontWeight: "500",
														paddingLeft: "0",
													}}
												>
													{report.mobileNumber}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography
													sx={{
														fontSize: "15px",
														fontWeight: "500",
														paddingLeft: "0",
													}}
												>
													{report.email}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography
													sx={{
														fontSize: "15px",
														fontWeight: "500",
														paddingLeft: "0",
													}}
												>
													{report.enrolledDate}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography
													sx={{
														fontSize: "15px",
														fontWeight: "500",
														paddingLeft: "0",
													}}
												>
													{report.amountPaid}
												</Typography>
											</TableCell>
										</TableRow>
									))}
							</TableBody>
						</Table>

						<Table>
							<TableRow>
								<TableCell>
									<Typography variant="h5">
										Total Enrolled User Count
									</Typography>
								</TableCell>
								<TableCell>
									<Typography
										sx={{
											fontSize: "15px",
											fontWeight: "500",
											marginRight: "4rem",
											textAlign: "left",
											color: "red",
										}}
									>
										{courseReport.totalEnrolledUserCount}
									</Typography>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Typography variant="h5">Total Amount Paid</Typography>
								</TableCell>
								<TableCell>
									<Typography
										sx={{
											fontSize: "15px",
											fontWeight: "500",
											marginRight: "4rem",
											textAlign: "left",
											color: "red",
										}}
									>
										{courseReport.totalAmountPaid}
									</Typography>
								</TableCell>
							</TableRow>
						</Table>

						{loading ? (
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: "200px",
								}}
							>
								<CircularProgress />
							</Box>
						) : null}
						{error ? (
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: "200px",
								}}
							>
								{error}
							</Box>
						) : null}
					</Box>
				</CardContent>
			</Card>
		</Box>
	);
}
