import React, { useEffect, useState } from "react";
import BASE_URL from "../../subcomponents/Config";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Box, CircularProgress } from "@mui/material";
import "../../Styles/Articles/articlesDetails.scss";
import SocialMediaShare from "../components/SocialMediaShare";
import { FaCopy } from "react-icons/fa6";
import CopyClipboard from "../components/CopyToClipboard";
import useLoading from "../../Hooks/useLoading";
import Loading from "../components/Loading";

const ArticlesDetails = () => {
	const { id } = useParams();
	const { loading, showLoading, hideLoading } = useLoading();
	const [newsArticle, setNewsArticle] = useState({});
	const [imageUrls, setImageUrls] = useState([]);
	const [error, setError] = useState("");
	const [cookies, setCookie] = useCookies(["userToken"]);
	const [token, setToken] = useState(cookies.userToken || "");

	useEffect(() => {
		setToken(cookies.userToken || "");
	}, [cookies]);

	useEffect(() => {
		VacancyDetails();
		window.scrollTo(0, 0);
	}, []);

	const VacancyDetails = async () => {
		showLoading();
		try {
			const response = await fetch(`${BASE_URL}/api/v1/news/${id}`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
					mode: "cors",
				},
			});
			if (response.ok) {
				const getApi = await response.json();
				setNewsArticle(getApi.newsArticle);
				setImageUrls(getApi.newsArticle.imageUrls);
				hideLoading();
			} else {
				const error = await response.json();
				setError(error.message);
				hideLoading();
			}
		} catch (error) {
			showLoading();
		}
	};

	return (
		<>
			<Navbar />
			{loading ? <Loading /> : null}
			{error && <div>{error}</div>}
			{!loading && (
				<main>
					<div className="articlesDetails">
						{newsArticle && (
							<div className="main_article">
								<div className="article_img">
									<img src={imageUrls[0]} alt="img" />
								</div>
								<div className="article_para">
									<h5>{newsArticle.title}</h5>
									<div
										className="details_article"
										dangerouslySetInnerHTML={{
											__html: (newsArticle.details || "").replace(/\n/g, "<br />")
										}}
									></div>
								</div>
								{imageUrls &&
									imageUrls.map((data, index) => (
										<div key={index}>
											{index !== 0 ? (
												<div className="article_img">
													<img src={data} alt="image in map" />
												</div>
											) : null}
										</div>
									))}
								<div>{newsArticle.createdDate}</div>
							</div>
						)}
						{loading ? (
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: "200px",
								}}
							>
								<CircularProgress />
							</Box>
						) : null}
						<div className="article-socialMedia">
							<span className="article-socialMediaShare">
								Share article to Social Media:{" "}
							</span>
							<CopyClipboard detailsId={id} />
							<SocialMediaShare detailsId={id} />
						</div>
					</div>
				</main>
			)}
			<Footer />
		</>
	);
};

export default ArticlesDetails;
