import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useCookies } from "react-cookie";
import BASE_URL from "../../subcomponents/Config";
import { toast, ToastContainer } from "react-toastify";
import {
	TextField,
	Button,
	Typography,
	Container,
	Box,
	CircularProgress,
	TextareaAutosize ,
} from "@mui/material";
import useFormValidation from "../../Hooks/useFormValidation";

const AdminLoginForm = styled("form")({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	border: "1px solid #ccc",
	padding: "20px",
	borderRadius: "5px",
});

const initialFormState = {
	title: "",
	details: "",
	images: [],
};

export default function AddArticle() {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [cookies] = useCookies(["adminToken"]);
	const [adminToken, setAdminToken] = useState(cookies.adminToken || "");
	const {
		formData,
		setFormData,
		handleFilesChange,
		setErrors,
		errors,
		handleChange,
		validateForm,
	} = useFormValidation(initialFormState);

	useEffect(() => {
		setAdminToken(cookies.adminToken || "");
	}, [cookies]);

	const handleSubmit = async (e) => {
		e.preventDefault();

		const validationErrors = validateForm(formData);

		if (Object.keys(validationErrors).length === 0) {
			const data = new FormData();
			data.append("title", formData.title);
			data.append("details", formData.details);
			formData.images.forEach((file) => {
				data.append("images", file);
			});
			setLoading(true);
			try {
				const response = await fetch(`${BASE_URL}/api/v1/news/postArticle`, {
					method: "POST",
					headers: {
						Authorization: `Bearer ${adminToken}`,
					},
					body: data,
				});

				if (response.ok) {
					const userData = await response.json();
					// alert(userData.message);
					toast.success(userData.message);
					setLoading(false);
					setFormData(initialFormState);
				} else {
					const error = await response.json();
					setError(error.message);
					setLoading(false);
				}
			} catch (error) {
				console.log(error.message);
			}
		} else {
			setErrors(validationErrors);
		}
	};

	return (
		<>
		<ToastContainer position="top-center" autoClose={2000} />
		<Container maxWidth="xs">
			<AdminLoginForm onSubmit={handleSubmit}>
				<Typography variant="h2" gutterBottom>
					Post New News Article
				</Typography>
				{error && (
					<Typography variant="subtitle2" color="error" gutterBottom>
						{error}!!
					</Typography>
				)}
				<Box width="100%" marginBottom="20px">
					<TextField
						label="Article Title"
						variant="outlined"
						name="title"
						fullWidth
						value={formData.title}
						onChange={handleChange}
					/>
					{errors.title && <span style={{ color: "red" }}>{errors.title}</span>}
				</Box>
				<Box width="100%" marginBottom="20px">
					<label htmlFor="article-details">Article Details</label>
					<textarea
						id="article-details"
						name="details"
						style={{
							width: "100%",
							height: "150px", // Set fixed height
							padding: "10px",
							border: "1px solid #ccc",
							borderRadius: "4px",
							resize: "none", // Prevent resizing to maintain fixed height
							overflowY: "auto" // Enable vertical scrollbar for overflow
						}}
						value={formData.details}
						onChange={handleChange}
					/>
					{errors.details && (
						<span style={{ color: "red" }}>{errors.details}</span>
					)}
				</Box>
				<Box width="100%" marginBottom="20px">
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
							border: "1px solid #ccc",
							padding: "10px",
							borderRadius: "5px",
						}}
					>
						<Typography variant="body1" sx={{ flexGrow: 1, color: "gray" }}>
							Select Files:
						</Typography>
						<input
							id="file-upload"
							type="file"
							multiple
							onChange={handleFilesChange}
							accept="image/*"
							style={{ display: "none" }} // Hides the default file input UI
							name="images"
						/>

						<label htmlFor="file-upload">
							<Button variant="outlined" component="span">
								Select
							</Button>
						</label>
					</Box>
					{formData.images &&
						formData.images.map((image) => (
							<Typography
								variant="body2"
								sx={{ marginTop: "10px", color: "gray", margin: "2px" }}
							>
								{image.name}
							</Typography>
						))}

					{errors.images && (
						<span style={{ color: "red" }}>{errors.images}</span>
					)}
				</Box>
				<Button type="submit" variant="contained" color="secondary">
					{loading ? (
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: "10px",
							}}
						>
							<CircularProgress />
						</Box>
					) : (
						<Box >Post News Article</Box>
					)}
				</Button>
			</AdminLoginForm>
		</Container>
		</>
	);
}
